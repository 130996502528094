<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2015-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        />
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :total-items="24"
          class="px-1 pb-1"
          item-key="date"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-right">
                {{ props.item.day }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.TBG }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.TBG39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.TBG50 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.TBG59 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.TBG89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.TBG150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.SP39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.SP89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.SP150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KDA39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KDA89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KDA150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.NS39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.NS89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.NS150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KLC39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KLC89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KLC150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KC39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KC89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KC150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KU39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KU89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.KU150 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.PNG39 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.PNG89 }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.PNG150 }}
              </td>
              <!-- <td class="text-xs-center info">
                {{ props.item.total }}
              </td> -->
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template slot="footer">
            <tr
              class="info"
            >
              <td class="text-xs-right white--text">
                Total Monthly
              </td>
              <td class="text-xs-center white--text">
                {{ totals.TBG }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.TBG39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.TBG50 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.TBG59 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.TBG89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.TBG150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.SP39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.SP89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.SP150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KDA39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KDA89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KDA150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.NS39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.NS89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.NS150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KLC39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KLC89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KLC150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KC39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KC89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KC150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KU39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KU89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.KU150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.PNG39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.PNG89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ totals.PNG150 }}
              </td>
              <!-- <td class="text-xs-center white">
                {{ totals.total }}
              </td> -->
            </tr>
          </template>
          <template slot="footer">
            <tr
              class="orange"
            >
              <td class="text-xs-right white--text">
                Total Sub
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tTBG }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tTBG39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tTBG50 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tTBG59 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tTBG89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tTBG150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tSP39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tSP89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tSP150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKDA39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKDA89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKDA150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tNS39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tNS89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tNS150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKLC39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKLC89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKLC150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKC39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKC89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKC150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKU39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKU89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tKU150 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tPNG39 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tPNG89 }}
              </td>
              <td class="text-xs-center white--text">
                {{ subTotals.tPNG150 }}
              </td>
              <!-- <td class="text-xs-center white">
                {{ totalSub.total }}
              </td> -->
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import apexcharts from '@/mixins/apexcharts'

const reportTitle = 'Daily FC Plan Distribution'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  mixins: [apexcharts],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Daily FC Plan Distribution', disabled: true,
        },
      ],
      expand: true,
      filter: 'All',
      headers: [
        {
          align: 'right',
          sortable: false,
          text: 'Date',
          value: 'date',
        },
        {
          align: 'center',
          sortable: false,
          text: 'TBG PREPAID',
          value: 'TBG',
        },
        {
          align: 'center',
          sortable: false,
          text: 'TBG39',
          value: 'TBG39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'TBG50',
          value: 'TBG50',
        },
        {
          align: 'center',
          sortable: false,
          text: 'TBG59',
          value: 'TBG59',
        },
        {
          align: 'center',
          sortable: false,
          text: 'TBG89',
          value: 'TBG89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'TBG150',
          value: 'TBG150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'SP39',
          value: 'SP39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'SP89',
          value: 'SP89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'SP150',
          value: 'SP150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KDA39',
          value: 'KDA39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KDA89',
          value: 'KDA89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KDA150',
          value: 'KDA150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'NS39',
          value: 'NS39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'NS89',
          value: 'NS89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'NS150',
          value: 'NS150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KLC39',
          value: 'KLC39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KLC89',
          value: 'KLC89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KLC150',
          value: 'KLC150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KC39',
          value: 'KC39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KC89',
          value: 'KC89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KC150',
          value: 'KC150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KU39',
          value: 'KU39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KU89',
          value: 'KU89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'KU150',
          value: 'KU150',
        },
        {
          align: 'center',
          sortable: false,
          text: 'PNG39',
          value: 'PNG39',
        },
        {
          align: 'center',
          sortable: false,
          text: 'PNG89',
          value: 'PNG89',
        },
        {
          align: 'center',
          sortable: false,
          text: 'PNG150',
          value: 'PNG150',
        },
        // {
        //   align: 'center',
        //   sortable: false,
        //   text: 'Total',
        //   value: 'total',
        // },
      ],
      items: [],
      total: [],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      showChart: false,
      title: reportTitle,
    }
  },
  computed: {
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    onexox2: function () {
      return this.total.filter(item => item.onex)
    },
    xox2: function () {
      return this.total.filter(item => !item.onex)
    },
    all: function () {
      const all = []
      for (let i = 0; i < this.onexox.length; i++) {
        all.push({
          date: this.onexox[i].date,
          TBG: this.onexox[i].TBG + (this.xox[i] ? this.xox[i].TBG : 0),
          TBG39: this.onexox[i].TBG39 + (this.xox[i] ? this.xox[i].TBG39 : 0),
          TBG50: this.onexox[i].TBG50 + (this.xox[i] ? this.xox[i].TBG50 : 0),
          KLC39: this.onexox[i].KLC39 + (this.xox[i] ? this.xox[i].KLC39 : 0),
          SP39: this.onexox[i].SP39 + (this.xox[i] ? this.xox[i].SP39 : 0),
          KDA39: this.onexox[i].KDA39 + (this.xox[i] ? this.xox[i].KDA39 : 0),
          NS39: this.onexox[i].NS39 + (this.xox[i] ? this.xox[i].NS39 : 0),
          KC39: this.onexox[i].KC39 + (this.xox[i] ? this.xox[i].KC39 : 0),
          KU39: this.onexox[i].KU39 + (this.xox[i] ? this.xox[i].KU39 : 0),
          PNG39: this.onexox[i].PNG39 + (this.xox[i] ? this.xox[i].PNG39 : 0),
          TBG59: this.onexox[i].TBG59 + (this.xox[i] ? this.xox[i].TBG59 : 0),
          TBG89: this.onexox[i].TBG89 + (this.xox[i] ? this.xox[i].TBG89 : 0),
          KLC89: this.onexox[i].KLC89 + (this.xox[i] ? this.xox[i].KLC89 : 0),
          SP89: this.onexox[i].SP89 + (this.xox[i] ? this.xox[i].SP89 : 0),
          KDA89: this.onexox[i].KDA89 + (this.xox[i] ? this.xox[i].KDA89 : 0),
          NS89: this.onexox[i].NS89 + (this.xox[i] ? this.xox[i].NS89 : 0),
          KC89: this.onexox[i].KC89 + (this.xox[i] ? this.xox[i].KC89 : 0),
          KU89: this.onexox[i].KU89 + (this.xox[i] ? this.xox[i].KU89 : 0),
          PNG89: this.onexox[i].PNG89 + (this.xox[i] ? this.xox[i].PNG89 : 0),
          TBG150: this.onexox[i].TBG150 + (this.xox[i] ? this.xox[i].TBG150 : 0),
          KLC150: this.onexox[i].KLC150 + (this.xox[i] ? this.xox[i].KLC150 : 0),
          SP150: this.onexox[i].SP150 + (this.xox[i] ? this.xox[i].SP150 : 0),
          KDA150: this.onexox[i].KDA150 + (this.xox[i] ? this.xox[i].KDA150 : 0),
          NS150: this.onexox[i].NS150 + (this.xox[i] ? this.xox[i].NS150 : 0),
          KC150: this.onexox[i].KC150 + (this.xox[i] ? this.xox[i].KC150 : 0),
          KU150: this.onexox[i].KU150 + (this.xox[i] ? this.xox[i].KU150 : 0),
          PNG150: this.onexox[i].PNG150 + (this.xox[i] ? this.xox[i].PNG150 : 0),
        })
      }

      return all
    },
    all2: function () {
      const all2 = []
      for (let i = 0; i < this.onexox2.length; i++) {
        all2.push({
          tTBG: this.onexox2[i].tTBG + (this.xox2[i] ? this.xox2[i].tTBG : 0),
          tTBG39: this.onexox2[i].tTBG39 + (this.xox2[i] ? this.xox2[i].tTBG39 : 0),
          tTBG50: this.onexox2[i].tTBG50 + (this.xox2[i] ? this.xox2[i].tTBG50 : 0),
          tKLC39: this.onexox2[i].tKLC39 + (this.xox2[i] ? this.xox2[i].tKLC39 : 0),
          tSP39: this.onexox2[i].tSP39 + (this.xox2[i] ? this.xox2[i].tSP39 : 0),
          tKDA39: this.onexox2[i].tKDA39 + (this.xox2[i] ? this.xox2[i].tKDA39 : 0),
          tNS39: this.onexox2[i].tNS39 + (this.xox2[i] ? this.xox2[i].tNS39 : 0),
          tKC39: this.onexox2[i].tKC39 + (this.xox2[i] ? this.xox2[i].tKC39 : 0),
          tKU39: this.onexox2[i].tKU39 + (this.xox2[i] ? this.xox2[i].tKU39 : 0),
          tPNG39: this.onexox2[i].tPNG39 + (this.xox2[i] ? this.xox2[i].tPNG39 : 0),
          tTBG59: this.onexox2[i].tTBG59 + (this.xox2[i] ? this.xox2[i].tTBG59 : 0),
          tTBG89: this.onexox2[i].tTBG89 + (this.xox2[i] ? this.xox2[i].tTBG89 : 0),
          tKLC89: this.onexox2[i].tKLC89 + (this.xox2[i] ? this.xox2[i].tKLC89 : 0),
          tSP89: this.onexox2[i].tSP89 + (this.xox2[i] ? this.xox2[i].tSP89 : 0),
          tKDA89: this.onexox2[i].tKDA89 + (this.xox2[i] ? this.xox2[i].tKDA89 : 0),
          tNS89: this.onexox2[i].tNS89 + (this.xox2[i] ? this.xox2[i].tNS89 : 0),
          tKC89: this.onexox2[i].tKC89 + (this.xox2[i] ? this.xox2[i].tKC89 : 0),
          tKU89: this.onexox2[i].tKU89 + (this.xox2[i] ? this.xox2[i].tKU89 : 0),
          tPNG89: this.onexox2[i].tPNG89 + (this.xox2[i] ? this.xox2[i].tPNG89 : 0),
          tTBG150: this.onexox2[i].tTBG150 + (this.xox2[i] ? this.xox2[i].tTBG150 : 0),
          tKLC150: this.onexox2[i].tKLC150 + (this.xox2[i] ? this.xox2[i].tKLC150 : 0),
          tSP150: this.onexox2[i].tSP150 + (this.xox2[i] ? this.xox2[i].tSP150 : 0),
          tKDA150: this.onexox2[i].tKDA150 + (this.xox2[i] ? this.xox2[i].tKDA150 : 0),
          tNS150: this.onexox2[i].tNS150 + (this.xox2[i] ? this.xox2[i].tNS150 : 0),
          tKC150: this.onexox2[i].tKC150 + (this.xox2[i] ? this.xox2[i].tKC150 : 0),
          tKU150: this.onexox2[i].tKU150 + (this.xox2[i] ? this.xox2[i].tKU150 : 0),
          tPNG150: this.onexox2[i].tPNG150 + (this.xox2[i] ? this.xox2[i].tPNG150 : 0),
        })
      }

      return all2
    },
    filteredItems: function () {
      let items
      if (this.filter === 'OneXOX') items = this.onexox
      else if (this.filter === 'XOX') items = this.xox
      else items = this.all

      for (const item of items) {
        const date = new Date(DateTime.fromSQL(item.date).toMillis())
        item.day = date.getDate()
      }

      return items
    },
    filteredItems2: function () {
      let total
      if (this.filter === 'OneXOX') total = this.onexox2
      else if (this.filter === 'XOX') total = this.xox2
      else total = this.all2

      return total
    },
    totals: function () {
      const items = this.filteredItems
      const totals = {
        TBG: 0,
        TBG39: 0,
        TBG50: 0,
        KLC39: 0,
        SP39: 0,
        KDA39: 0,
        NS39: 0,
        KC39: 0,
        KU39: 0,
        PNG39: 0,
        TBG59: 0,
        TBG89: 0,
        KLC89: 0,
        SP89: 0,
        KDA89: 0,
        NS89: 0,
        KC89: 0,
        KU89: 0,
        PNG89: 0,
        TBG150: 0,
        KLC150: 0,
        SP150: 0,
        KDA150: 0,
        NS150: 0,
        KC150: 0,
        KU150: 0,
        PNG150: 0,
        // total: 0,
      }

      for (const item of items) {
        totals.TBG += item.TBG
        totals.TBG39 += item.TBG39
        totals.TBG50 += item.TBG50
        totals.KLC39 += item.KLC39
        totals.SP39 += item.SP39
        totals.KDA39 += item.KDA39
        totals.NS39 += item.NS39
        totals.KC39 += item.KC39
        totals.KU39 += item.KU39
        totals.PNG39 += item.PNG39
        totals.TBG59 += item.TBG59
        totals.TBG89 += item.TBG89
        totals.KLC89 += item.KLC89
        totals.SP89 += item.SP89
        totals.KDA89 += item.KDA89
        totals.NS89 += item.NS89
        totals.KC89 += item.KC89
        totals.KU89 += item.KU89
        totals.PNG89 += item.PNG89
        totals.TBG150 += item.TBG150
        totals.KLC150 += item.KLC150
        totals.SP150 += item.SP150
        totals.KDA150 += item.KDA150
        totals.NS150 += item.NS150
        totals.KC150 += item.KC150
        totals.KU150 += item.KU150
        totals.PNG150 += item.PNG150

        // totals.total += item.total
      }

      return totals
    },

    subTotals: function () {
      const total = this.filteredItems2
      const subTotals = {
        tTBG: 0,
        tTBG39: 0,
        tTBG50: 0,
        tKLC39: 0,
        tSP39: 0,
        tKDA39: 0,
        tNS39: 0,
        tKC39: 0,
        tKU39: 0,
        tPNG39: 0,
        tTBG59: 0,
        tTBG89: 0,
        tKLC89: 0,
        tSP89: 0,
        tKDA89: 0,
        tNS89: 0,
        tKC89: 0,
        tKU89: 0,
        tPNG89: 0,
        tTBG150: 0,
        tKLC150: 0,
        tSP150: 0,
        tKDA150: 0,
        tNS150: 0,
        tKC150: 0,
        tKU150: 0,
        tPNG150: 0,
        // total: 0,
      }

      for (const item of total) {
        subTotals.tTBG += item.tTBG
        subTotals.tTBG39 += item.tTBG39
        subTotals.tTBG50 += item.tTBG50
        subTotals.tKLC39 += item.tKLC39
        subTotals.tSP39 += item.tSP39
        subTotals.tKDA39 += item.tKDA39
        subTotals.tNS39 += item.tNS39
        subTotals.tKC39 += item.tKC39
        subTotals.tKU39 += item.tKU39
        subTotals.tPNG39 += item.tPNG39
        subTotals.tTBG59 += item.tTBG59
        subTotals.tTBG89 += item.tTBG89
        subTotals.tKLC89 += item.tKLC89
        subTotals.tSP89 += item.tSP89
        subTotals.tKDA89 += item.tKDA89
        subTotals.tNS89 += item.tNS89
        subTotals.tKC89 += item.tKC89
        subTotals.tKU89 += item.tKU89
        subTotals.tPNG89 += item.tPNG89
        subTotals.tTBG150 += item.tTBG150
        subTotals.tKLC150 += item.tKLC150
        subTotals.tSP150 += item.tSP150
        subTotals.tKDA150 += item.tKDA150
        subTotals.tNS150 += item.tNS150
        subTotals.tKC150 += item.tKC150
        subTotals.tKU150 += item.tKU150
        subTotals.tPNG150 += item.tPNG150

        // subTotals.total += item.total
      }

      return subTotals
    },
  },
  watch: {
    monthOf: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2015, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivations (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getActivationsFcByMonth.php', params)
        .then(function (response) {
          this.items = response.data.month
          this.total = response.data.total
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
